<template>
  <div class="mainPage container">
    <div class="searchbox">
      <el-form :model="searchForm" label-width="80px" inline>
        <el-form-item label="促销名称">
          <el-input v-model="searchForm.promotion_name"></el-input>
        </el-form-item>

        <el-form-item label="促销类型">
          <el-select v-model="searchForm.promotion_type" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="满减" :value="1"></el-option>
            <el-option label="每满减" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="searchForm.promotion_status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="未开始" :value="1"></el-option>
            <el-option label="进行中" :value="2"></el-option>
            <el-option label="已结束" :value="3"></el-option>
            <el-option label="已禁用" :value="4"></el-option>
          </el-select>
        </el-form-item>

        <el-button type="primary" size="small" @click="searchBtn"
          >查询</el-button
        >
      </el-form>
    </div>
    <div
      v-if="module_id_array.includes('192') || module_id_array.includes('999')"
    >
      <el-button type="primary" @click="toAdd">新增促销</el-button>
    </div>
    <div class="table-box">
      <el-table
        v-loading="tableLoading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
      >
        <el-table-column
          v-for="item in tableHeaders"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :fixed="item.fixed"
        >
          <template slot-scope="scope">
            <div v-if="item.prop == 'promotion_type'">
              {{ scope.row[item.prop] | promotionTypeText }}
            </div>
            <div v-else-if="item.prop == 'activityTime'">
              <div>{{ scope.row["time_start"] | dateFormat }}</div>
              <span>至</span>
              <div>{{ scope.row["time_end"] | dateFormat }}</div>
            </div>

            <div v-else-if="item.prop == 'createTime'">
              <span>{{ scope.row["create_time"] | dateFormat }}</span>
            </div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <template
              v-if="
                module_id_array.includes('193') ||
                  module_id_array.includes('999')
              "
            >
              <el-button
                v-if="
                  scope.row.promotion_status == 1 ||
                    scope.row.promotion_status == 2
                "
                type="text"
                @click="edit(scope.row)"
                >编辑</el-button
              >
            </template>
            <template
              v-if="
                module_id_array.includes('193') ||
                  module_id_array.includes('999')
              "
            >
              <el-button
                v-if="
                  scope.row.promotion_status == 2 ||
                    scope.row.promotion_status == 4
                "
                type="text"
                @click="changeStatus(scope.row)"
                >{{ scope.row.promotion_status | statusText }}</el-button
              >
            </template>

            <el-button
              v-if="
                module_id_array.includes('194') ||
                  module_id_array.includes('999')
              "
              type="text"
              @click="check(scope.row)"
              >查看</el-button
            >

            <el-button
              v-if="
                module_id_array.includes('192') ||
                  module_id_array.includes('999')
              "
              type="text"
              @click="copy(scope.row)"
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-show="totalNum > 0"
      :total="totalNum"
      :page.sync="pagination.page"
      :limit.sync="pagination.pageNumber"
      @pagination="getList"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/components/Pagination/index";
import operation from "../../../api/operation";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      tableLoading: false,
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      searchForm: {
        promotion_name: "",
        promotion_status: "",
        promotion_type: "",
      },
      tableHeaders: [
        {
          label: "促销名称",
          prop: "promotion_name",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "促销类型",
          prop: "promotion_type",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "优惠内容",
          prop: "promotion_content_str",
          width: "auto",
          align: "center",
          fixed: false,
        },

        {
          label: "活动时间",
          prop: "activityTime",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "销量",
          prop: "buy_num",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "参加人数",
          prop: "userNum",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "新客数",
          prop: "new_userNum",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "状态",
          prop: "status_str",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: "auto",
          align: "center",
          fixed: false,
        },
      ],
      totalNum: 0,
      pagination: {
        page: 1,
        pageNumber: 10,
      },
      tableData: [],
    };
  },
  filters: {
    promotionTypeText(val) {
      switch (val) {
        case 1:
          return "满减";
        case 2:
          return "每满减";
      }
    },
    statusText(val) {
      switch (val) {
        case 2:
          return "禁用";
        case 4:
          return "开启";
      }
    },
    dateFormat(val) {
      return !val ? "" : dayjs.unix(val).format("YYYY-MM-DD HH:mm");
    },
  },
  created() {
    this.getList();
  },
  methods: {
    searchBtn() {
      this.pagination.page = 1;
      this.getList();
    },
    getList() {
      const params = {
        ...this.pagination,
        ...this.searchForm,
      };
      this.tableLoading = true;
      operation
        .getPromotionActivityLists(params)
        .then((res) => {
          let { code, data } = res;
          if (code === 0) {
            this.tableData = data.list;
            this.totalNum = data.total;
          }
          this.tableLoading = false;
        })
        .catch(() => (this.tableLoading = false));
    },
    toAdd() {
      this.$router.push({
        path: "/operation/operation/promotionDetails",
        query: {
          isAdd: 1,
        },
      });
    },
    check(val) {
      this.$router.push({
        path: "/operation/operation/promotionDetails",
        query: {
          isCheck: 1,
          promotion_id: val.promotion_id,
        },
      });
    },
    edit(val) {
      this.$router.push({
        path: "/operation/operation/promotionDetails",
        query: {
          isAdd: 2,
          promotion_id: val.promotion_id,
          isNow: val.promotion_status == 2 ? 1 : 0,
        },
      });
    },
    copy(val) {
      this.$router.push({
        path: "/operation/operation/promotionDetails",
        query: {
          isAdd: 1,
          isCopy: 1,
          promotion_id: val.promotion_id,
        },
      });
    },
    changeStatus(val) {
      let parmas = {
        promotion_id: val.promotion_id,
        status:
          val.promotion_status == 2
            ? "0"
            : val.promotion_status == 4
            ? "1"
            : "",
      };
      operation.savePromotionStatus(parmas).then((res) => {
        this.getList();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.searchbox {
  .input-with-select {
    width: 240px;
    margin-left: 10px;
  }
}
</style>
